import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { faSadTear } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <section id="error">
      <h1>Not Found <FontAwesomeIcon icon={faSadTear} className="icon" /></h1>
      <p>Oops! Looks like this page doesn't exist.</p>
      <p><a href="/">Return to Rallyworks.ca</a></p>
    </section>
  </Layout>
)

export default NotFoundPage
